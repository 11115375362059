import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React, { useState, useEffect } from 'react'

function CmsText({ component }) {

    var componentStyle = typeof component.options.background != 'undefined' ? 
    {
        backgroundImage: `url(${component.options.background})`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
        height:'100%'
    } : {};



    return (
        <div className={`h-full text`}>
            <div 
                dangerouslySetInnerHTML={{ __html: component.options.content }} 
                style={componentStyle} 
            />
        </div>
        
    )
}

export default CmsText